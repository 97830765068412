import React from "react"
import T from "@mui/material/Typography"
import Button from "../components/design-system/Button"
import SEO from "../components/SEO"
import { navigate } from "gatsby"
import NoScript from "../components/NoScript"
import ButtonWrapper from "../components/ButtonWrapper"

const FourOhFour: React.FC = () => {
  return (
    <>
      <SEO title="Page not found" />
      <NoScript />
      <T variant="h1" component="h1">
        404
      </T>
      <T variant="body1">{"Unfortunately that page doesn't exist."}</T>
      <ButtonWrapper>
        <Button
          role="link"
          data-test-button="home"
          onClick={() => navigate("/")}
        >
          Go back home
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default FourOhFour
